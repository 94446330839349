import type { CustomFormItem } from "astro-types/components";
import { useId, type BaseSyntheticEvent } from "react";

import Select, { StylesConfig } from "react-select";
import InputContainer from "./InputContainer";

interface CustomSelectProps {
  items: CustomFormItem[];
  label?: string;
  isRequired?: boolean;
  placeholder?: string;
  initialValue?: string;
  info?: string;
  disabled?: boolean;
  name: string;
}

interface Option {
  value: string;
  label: string;
}

const selectStyles: StylesConfig = {
  option: (styles) => {
    return {
      ...styles,
      color: "var(--light-color)",
    };
  },
};

export default function CustomSelect({
  items,
  label,
  isRequired,
  placeholder = "Select...",
  initialValue,
  info,
  disabled,
  name,
}: CustomSelectProps) {
  const inputId = useId();

  const options = items.map((item) => {
    return {
      label: item.fields.Label,
      value: item.fields.Value,
    } satisfies Option;
  });

  const defaultValue = !initialValue
    ? null
    : options.find((option) => option.value === initialValue) ?? null;

  function handleChange(e: BaseSyntheticEvent) {
    if (e.target)
      e.target
        .closest(".form-input-container")
        .querySelector(".error-message")
        ?.remove();
  }

  return (
    <InputContainer inputId={inputId} info={info} label={label}>
      <Select
        required={isRequired}
        inputId={inputId}
        instanceId={"asd"}
        isDisabled={disabled}
        isLoading={false}
        isClearable
        isRtl={false}
        isSearchable
        options={options}
        defaultValue={defaultValue}
        placeholder={placeholder}
        name={name}
        className="react-select-container"
        classNamePrefix="react-select"
        onFocus={handleChange}
        styles={selectStyles}
      />
    </InputContainer>
  );
}
