import { useRef, useEffect, useState } from "react";
import SignaturePad, { type PointGroup } from "signature_pad";

import styles from "./Signature.module.css";
import InputContainer from "../InputContainer";

type SignatureProps = {
  label: string;
  info?: string;
  name?: string;
};

function adjustPointerPosition(canvas: HTMLCanvasElement | null) {
  if (!canvas) return;
  canvas.width = canvas.offsetWidth;
  canvas.height = canvas.offsetHeight;
}

const Signature: React.FC<SignatureProps> = ({
  label,
  info,
  name,
}): JSX.Element => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const signaturePad = useRef<SignaturePad | null>(null);
  const [strokeContent, setStrokeContent] = useState<string | null>(null);

  useEffect(() => {
    if (!canvasRef || !canvasRef?.current) return;

    signaturePad.current = new SignaturePad(canvasRef.current, {
      backgroundColor: "#ffffff",
      dotSize: 3,
    });

    adjustPointerPosition(canvasRef.current);

    signaturePad.current.addEventListener("endStroke", () => {
      if (signaturePad.current) {
        setStrokeContent(signaturePad.current.toDataURL());
      }
    });

    const handleResize = () => adjustPointerPosition(canvasRef.current);
    document.addEventListener("resize", handleResize);

    return () => {
      document.removeEventListener("resize", handleResize);
    };
  }, []);

  function clearCanvas() {
    if (!signaturePad.current) return;
    signaturePad.current.clear();
  }

  return (
    <InputContainer info={info} label={label}>
      <div className={styles.signaturePadBody}>
        <canvas ref={canvasRef} />
        <button type="button" onClick={clearCanvas} className={styles.button}>
          X
        </button>
      </div>
      <input type="hidden" name={name} value={strokeContent || ""} />
    </InputContainer>
  );
};

export default Signature;
