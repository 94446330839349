import { useId } from "react";
import type { CustomFormItem } from "astro-types/components";
import InputContainer from "./InputContainer";
import slugify from "slugify";

interface CustomRadioInputsProps {
  items: CustomFormItem[];
  label?: string;
  info?: string;
  name: string;
  disabled?: boolean;
  required?: boolean;
}

function CustomRadioInputs({
  items,
  label,
  info,
  name,
  disabled,
  required,
}: CustomRadioInputsProps) {
  return (
    <InputContainer label={label} info={info}>
      <div className="form-radio-container">
        {items.map(({ id, fields: { Label, Value, isChecked } }) => {
          const programmaticLabel = slugify(Label);
          return (
            <div key={id} className="form-radio-item">
              <input
                disabled={disabled}
                className="form-radio"
                type="radio"
                id={programmaticLabel}
                name={name}
                required={required}
                value={Value}
                defaultChecked={isChecked}
              />
              <label className="form-radio-label" htmlFor={programmaticLabel}>
                {Label}
              </label>
            </div>
          );
        })}
      </div>
    </InputContainer>
  );
}

export default CustomRadioInputs;
