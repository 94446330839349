import { useState, useEffect, useId } from "react";
import InputContainer from "./InputContainer";

interface CustomRangeInputProps {
  label?: string;
  value?: string;
  info?: string;
  name: string;
  step?: number;
  disabled?: boolean;
  required?: boolean;
}

function CustomRangeInput({
  label,
  value,
  info,
  name,
  step = 1,
  disabled = false,
  required,
}: CustomRangeInputProps) {
  const inputId = useId();
  const [rangeState, setRangeState] = useState<number>(0);

  useEffect(() => {
    let valueToBeUsed = 0;
    const numberValue = parseInt(value || "");

    if (isNaN(numberValue)) valueToBeUsed = 0;
    else valueToBeUsed = numberValue;

    setRangeState(valueToBeUsed);
  }, [value, setRangeState]);

  return (
    <InputContainer label={label} info={info} inputId={inputId}>
      <div className="form-range-container">
        <span className="form-range-label">{rangeState}</span>
        <input
          className="form-range-input"
          type="range"
          name={name}
          value={rangeState}
          step={step}
          onChange={(e) => setRangeState(parseInt(e.target.value))}
          disabled={disabled}
          required={required}
        />
      </div>
    </InputContainer>
  );
}

export default CustomRangeInput;
