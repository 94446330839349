interface InputContainerProps extends React.PropsWithChildren {
  label?: string;
  inputId?: string;
  info?: string;
}

function InputContainer({
  children,
  label,
  inputId,
  info,
}: InputContainerProps): JSX.Element {
  return (
    <div className="form-input-container">
      {label && (
        <label className="form-label" htmlFor={inputId}>
          {label}
        </label>
      )}
      {children}
      {info && <small className="form-info">{info}</small>}
    </div>
  );
}

export default InputContainer;
