import type { CustomFormItem } from "astro-types/components";
import { useId } from "react";
import InputContainer from "./InputContainer";
import { cn } from "../../../helpers/cn";

interface CustomCheckboxesProps {
  items: CustomFormItem[];
  label?: string;
  info?: string;
  code?: string;
}

function CustomCheckboxes({ items, label, info, code }: CustomCheckboxesProps) {
  const inputId = useId();
  return (
    <InputContainer inputId={inputId} label={label} info={info}>
      <div className={cn("form-checkboxes", code ? code : "")}>
        {items.map(
          ({
            id,
            fields: { isChecked, Label, isDisabled, isRequired, Name, Value },
          }) => {
            return (
              <div className="form-checkbox-container" key={id}>
                <div className="form-checkbox">
                  <input
                    key={id}
                    id={id}
                    disabled={isDisabled}
                    type="checkbox"
                    checked={isChecked}
                    name={Name}
                    required={isRequired}
                    value={Value || "Yes"}
                  />
                  <label
                    htmlFor={id}
                    dangerouslySetInnerHTML={{ __html: Label }}
                    className="form-checkbox-label"
                  />
                </div>
              </div>
            );
          },
        )}
      </div>
    </InputContainer>
  );
}

export default CustomCheckboxes;
