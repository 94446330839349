import { useId, type HTMLInputTypeAttribute } from "react";
import InputContainer from "./InputContainer";
interface TextInputProps {
  label?: string;
  isRequired?: boolean;
  placeholder?: string;
  initialValue?: string;
  info?: string;
  disabled?: boolean;
  name: string;
  type?: HTMLInputTypeAttribute;
}

function TextInput({
  label,
  isRequired = false,
  placeholder = "",
  initialValue = "",
  info = "",
  disabled = false,
  name,
  type = "text",
}: TextInputProps) {
  const inputId = useId();

  return (
    <InputContainer label={label} info={info} inputId={inputId}>
      <input
        className="form-input form-input--text"
        id={inputId}
        defaultValue={initialValue}
        placeholder={placeholder}
        required={isRequired}
        disabled={disabled}
        name={name}
        type={type}
      />
    </InputContainer>
  );
}

export default TextInput;
