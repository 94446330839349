import { useId } from "react";
import InputContainer from "./InputContainer";

interface CustomTextAreaProps {
  label?: string;
  name: string;
  initialValue?: string;
  info?: string;
  disabled?: boolean;
  required?: boolean;
}
function CustomTextArea({
  label,
  name,
  initialValue,
  info,
  disabled,
  required = false,
}: CustomTextAreaProps) {
  const inputId = useId();

  return (
    <InputContainer label={label} info={info} inputId={inputId}>
      <textarea
        required={required}
        id={inputId}
        className="form-textarea"
        disabled={disabled}
        defaultValue={initialValue}
        name={name}
      />
    </InputContainer>
  );
}

export default CustomTextArea;
