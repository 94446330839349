import React, { useState, useEffect, lazy, Suspense, useId } from "react";
import InputContainer from "./InputContainer";
import "react-phone-input-2/lib/style.css";
import googleLibPhoneNumber from "google-libphonenumber";

const LazyPhoneInput = lazy(() => import("react-phone-input-2"));

const phoneUtil = googleLibPhoneNumber.PhoneNumberUtil.getInstance();
interface FormPhoneInputInterface {
  defaultValue: string;
  label?: string;
  info?: string;
  name?: string;
  placeholder?: string;
  disabled?: boolean;
}

function FormPhoneInput({
  defaultValue,
  label,
  info,
  name,
  placeholder,
  disabled,
}: FormPhoneInputInterface) {
  const [isVisible, setIsVisible] = useState(false);
  const [phoneNumberData, setPhoneNumberData] = useState({
    error: false,
    phone: defaultValue,
  });
  const inputId = useId();

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.disconnect();
        }
      },
      { threshold: 0.1 }, // Load the component when 10% of it is visible
    );

    const target = document.getElementById(inputId);
    if (target) {
      observer.observe(target);
    }

    return () => observer.disconnect();
  }, [inputId]);

  function handlePhoneInputChange(e: string) {
    const phoneInput = document.querySelector(
      `[name="${name}"]`,
    ) as HTMLInputElement;

    try {
      const isNumberValid = phoneUtil.isValidNumberForRegion(
        phoneUtil.parse(e, "US"),
        "US",
      );
      setPhoneNumberData({ error: !isNumberValid, phone: e });
      if (phoneInput)
        phoneInput.setCustomValidity(!isNumberValid ? "invalid number" : "");
    } catch (err) {
      setPhoneNumberData({ error: true, phone: e });
      if (phoneInput) phoneInput.setCustomValidity("invalid number");
    }
  }

  return (
    <InputContainer label={label} inputId={inputId} info={info}>
      {isVisible ? (
        <Suspense fallback={<div>Loading...</div>}>
          <LazyPhoneInput
            disabled={disabled}
            inputProps={{
              id: inputId,
              name: name,
              style: {
                border:
                  phoneNumberData.phone && phoneNumberData.error
                    ? "1px solid red"
                    : "1px solid green",
              },
              className: phoneNumberData.error
                ? "form-input form-input--phone form-input--error"
                : "form-input form-input--phone",
              placeholder: placeholder,
            }}
            country="us"
            value={phoneNumberData.phone}
            onChange={handlePhoneInputChange}
          />
        </Suspense>
      ) : (
        <div id={inputId} style={{ height: "40px", border: "1px solid #ccc" }}>
          {/* Placeholder content while the component is not loaded */}
        </div>
      )}
      {phoneNumberData.phone && phoneNumberData.error && (
        <span className="error-message">{"Invalid phone number"}</span>
      )}
    </InputContainer>
  );
}

export default FormPhoneInput;
